


export function Cash_management(){

    return(
        <>
         <h1>Gestion bon et espéces</h1>
        </>
    )

}

