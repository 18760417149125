


export function Tank_management(){

    return(
        <>
         <h1>Gestion des citernes</h1>
        </>
    )

}

