


export function Fuel_consumption(){

    return(
        <>
         <h1>Consommation carburant</h1>
        </>
    )

}

