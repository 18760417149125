
export const AcquisitionOption = [
  { value: "Achat", label: "Achat Automobile" },
  { value: "Leasing", label: "Leasing Tractés" },
  { value: "Location", label: "Location" },
];
export const CategorieOption = [
  { value: "vehicule_automobile", label: "Véhicule Automobile" },
  { value: "vehicule_tractés", label: "Véhicule Tractés" },
  { value: "autre", label: "Autre" },
];
export const EtatOption =[
  { value: "vehicule_automobile", label: "Véhicule Automobile" },
  { value: "vehicule_tractés", label: "Véhicule Tractés" },
  { value: "autre", label: "Autre" },
];
export const TypeOption =[
  { value: "Touristique", label: "Touristique" },
  { value: "Utilitaire", label: "Utilitaire" },
  { value: "transport_commun", label: "Transport commun" },
  { value: "camion", label: "Camion" },
  { value: "camion_semi-remorque", label: "Camion (semi-remorque)" },
  { value: "Engins", label: "Engins" },
  { value: "specifique", label: "Spécifique" },
  { value: "remorque_usage_classique", label: "Remorque usage classique" },
  { value: "semi_remorque", label: "Semi Remorque" },
  { value: "autre", label: "Autre" },
];
export const TypeCarburantOption =[
  { value: "essence", label: "Essence" },
  { value: "gas_oil", label: "Gas oil" },
  { value: "GPL", label: "GPL" },
  { value: "electrique", label: "Eléctrique" },
];

export const AffectationVehicleOption =[
  { value: "logistique", label: "Logistique" },
  { value: "technique", label: "Technique" },
  { value: "commercial", label: "Commercial" },
  { value: "informatique", label: "Informatique" },
  { value: "approvisionnement", label: "Approvisionnement" },
];

export const DureeOption =[
  { value: "6", label: "6" },
  { value: "12", label: "12" },
  { value: "36", label: "36" },
  { value: "48", label: "48" },
  { value: "60", label: "60" },
 
];

