


export function Card_management(){

    return(
        <>
         <h1>Gestion des cartes</h1>
        </>
    )

}

